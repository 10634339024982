import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import pnfLogo from './pnflogo.jpg'; // Import the logo image

function AppHeader() {
  const navbarStyle = {
    letterSpacing: '0.5px',
    backgroundColor: '#D2A48C'
  };

  const logoStyle = {
    width: '100px', // Increased width for the logo
    marginRight: '10px',
  };

  const commonElementStyle = {
    fontFamily: 'Concert One, sans-serif', // Use Concert One font for other elements
  };
  
  const titleStyle = {
    fontSize: '15px', // Increased font size for the title
    fontWeight: '700', // Extra bold text
    color: '#A0522D', // Slightly darker light brown color (Hex code)
    textTransform: 'uppercase', // Uppercase text
    fontFamily: 'Rubik Bubbles, sans-serif', // Use Oswald font
  };

  return (
    <Navbar bg="light" expand="lg" className="mb-4" style={navbarStyle}>
      <Container>
        {/* Logo Image */}
        <img src={pnfLogo} alt="PNF Logo" style={logoStyle} />

        {/* Title */}
        <Navbar.Brand href="#home" style={titleStyle}>PAKODA N FALOODA CAFE</Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={commonElementStyle}>
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#works">Frozen Menu</Nav.Link>
            <Nav.Link href="#menu">Our Menu</Nav.Link>
            <Nav.Link href="#order">Order Frozen Packets</Nav.Link>
            <Nav.Link href="#contact">Contact Us</Nav.Link>
            <Nav.Link href="#footer">Social Media/Swiggy</Nav.Link>
            <Nav.Link href="#google-map">Location/Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default AppHeader;

import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function AppContact() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const handleContactSubmit = async (e) => {
    e.preventDefault();

    // Reset any previous errors
    setError(null);

    // Basic email format validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setError('Invalid email format.');
      return;
    }

    // Contact number validation: Allow only numbers
    const contactPattern = /^[0-9]+$/;
    if (!contactPattern.test(contactNumber)) {
      setError('Contact number should contain only numbers.');
      return;
    }

    // Create a contactData object
    const contactData = {
      fullName,
      email,
      contactNumber,
      message,
    };

    try {
      const response = await fetch('https://mohammadomarshaikh-backend.onrender.com/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contactData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Contact Form Submitted:', data);

        // Clear the form fields after a successful submission
        setFullName('');
        setEmail('');
        setContactNumber('');
        setMessage('');
      } else {
        setError('Error submitting the contact form.');
      }
    } catch (error) {
      setError('An error occurred while submitting the contact form.');
      console.error(error);
    }
  };


  return (
    <section id="contact" className="block contact-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Contact us</h2>
          <div className="subtitle">get connected with us</div>
        </div>
        <Form className="contact-form" onSubmit={handleContactSubmit}>
          <Row>
            <Col sm={4}>
              <Form.Control
                type="text"
                placeholder="Enter your full name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </Col>
            <Col sm={4}>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Col>
            <Col sm={4}>
              <Form.Control
                type="tel"
                placeholder="Enter your contact number"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Control
                as="textarea"
                placeholder="Enter your message to us "
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </Col>
          </Row>
          {error && <div className="text-danger">{error}</div>}
          <div className="btn-holder">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </Container>
      <div className="google-map" id="google-map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.2551183940072!2d73.8661275739914!3d18.517369969274366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c15edec3def9%3A0xd32a0f2ec04badce!2sPaKoda%20N%20Faluda%20Cafe!5e0!3m2!1sen!2sin!4v1701868523888!5m2!1sen!2sin"
        ></iframe>
      </div>
      <Container fluid>
        <div className="contact-info">
          <ul>
            <li>
              <i className="fas fa-envelope"></i>
              pakodanfaluda@gmail.com
            </li>
            <li>
              <i className="fas fa-phone"></i>
              (+91) 7058693866/7058693862
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              Rastha Peth, Pune, Maharashtra
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
}

export default AppContact;

import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

// Define an array of data for menu pages
var menuData = [
  {
    id: 1,
    image: require('../assets/images/PNFMENU.jpg'),
  },
  {
    id: 2,
    image: require('../assets/images/pnfmenupage2.jpg'),
  },
  {
    id: 3,
    image: require('../assets/images/pnfmenupage3.jpg'),
  },
  {
    id: 4,
    image: require('../assets/images/pnfmenupage4.jpg'),
  },
  {
    id: 5,
    image: require('../assets/images/pnfmenupage5.jpg'),
  },
  {
    id: 6,
    image: require('../assets/images/pnfmenupage6.jpg'),
  },
  {
    id: 7,
    image: require('../assets/images/pnfmenupage7.jpg'),
  },
  {
    id: 8,
    image: require('../assets/images/pnfmenupage8.jpg'),
  },
  // ... Repeat this structure for the remaining menu pages
];

function MenuCarousel() {
  return (
    <section id="menu" className="menu-block">
      {/* Create a Carousel component for menu pages */}
      <Carousel>
        {menuData.map(menu => (
          <Carousel.Item key={menu.id}>
            {/* Display an image for the menu page */}
            <img
              className="d-block w-100"
              src={menu.image}
              alt={"menu " + menu.id}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
}

export default MenuCarousel;


import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import AppHeader from './components/header';
import AppHero from './components/hero';
import AppAbout from './components/about';
import AppWorks from './components/works';
import Vegmenu from './components/vegmenu';
import AppContact from './components/contact';
import AppFooter from './components/footer';
import Order from './components/order';
import MenuCarousel from './components/menu';

function App() {
  return (
    <div className="App">
      <header id='header'>
        <AppHeader />
      </header>
      <main>
        <AppHero />
        <AppAbout />
        <AppWorks />
        <Vegmenu />
        <MenuCarousel />
        <Order />
        <AppContact />
      </main>
      <footer id="footer">
        <AppFooter />
      </footer>
    </div>
  );
}

export default App;

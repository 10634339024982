import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';

const vegData = [
  {
    id: 1,
    image: require('../assets/images/CHEESECORNNUGGETS.jpg'),
    title: 'Cheese Corn Nuggets 1KG 40PCS',
    subtitle: '₹600.00'

  },
  {
    id: 2,
    image: require('../assets/images/VEGSAMOSA.jpg'),
    title: 'Veg Samosa 1KG 50PCS',
    subtitle: '₹500.00'
    
  },
  {
    id: 3,
    image: require('../assets/images/PUNJABISAMOSA.jpg'),
    title: 'Punjabi Samosa 1KG 12PCS',
    subtitle: '₹200.00'
  },
  {
    id: 4,
    image: require('../assets/images/VEGSPRINGROLL.jpg'),
    title: 'Veg Spring Roll 1KG 33PCS',
    subtitle: '₹450.00'
  },
  {
    id: 5,
    image: require('../assets/images/CHEESECHILISAMOSA.jpg'),
    title: 'Cheese Chili Samosa 1KG 50PCS',
    subtitle: '₹500.00'
  },
  {
    id: 6,
    image: require('../assets/images/MACALOOTIKKI.jpg'),
    title: 'Mac Aloo Tikki 1KG 15PCS',
    subtitle: '₹400.00'
  },
  {
    id: 7,
    image: require('../assets/images/VEGBURGERPATTY.jpg'),
    title: 'VEG BURGER PATTY 1KG 15PCS',
    subtitle: '₹300.00'
  },
  
]

function Vegmenu() {
  const [active, setActive] = useState(1);

  // Calculate the index range for the current page
  const itemsPerPage = 6;
  const startIndex = (active - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the works data for the current page
  const currentWorks = vegData.slice(startIndex, endIndex);

  const handlePageClick = (number) => {
    setActive(number);
  };

  return (
    <section id="works" className="block works-block">
      <Container fluid>
        <div className="title-holder">
          <div className="subtitle">VEG</div>
        </div>
        <Row className='portfoliolist'>
          {currentWorks.map((works) => (
            <Col sm={4} key={works.id}>
              <div className='portfolio-wrapper'>
                <a href={works.link}>
                  <Image src={works.image} />
                  <div className='label text-center top'>
                    <h4 style={{ fontSize: '12px' }}>{works.title}</h4>
                    <p style={{ fontSize: '10px', position: 'relative', top: '-20px' }}>
                      {works.subtitle}
                    </p>
                    <p>{works.description}</p>
                  </div>
                </a>
              </div>
            </Col>
          ))}
        </Row>
        <Pagination>
          {Array.from({ length: Math.ceil(vegData.length / itemsPerPage) }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === active}
              onClick={() => handlePageClick(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </Container>
    </section>
  );
}

export default Vegmenu;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import img1 from '../assets/images/ABOUTCAFE.jpg';

function AppAbout() {
  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>About Us</h2>
          <div className="subtitle">learn more about us</div>
        </div>
        <Row>
          <Col sm={6}>
            {/* Display an image using the Bootstrap Image component */}
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            {/* Description of the about section */}
            <p>
              Indulge in the extraordinary at Pakoda N Falooda. Experience culinary perfection, impeccable service, and a delightful ambiance.
            </p>
            <p>
              We are committed to serve quality on a plate, delivering an unforgettable journey through flavors, creativity, and impeccable service. Our story begins with a relentless pursuit of perfection in every dish we create.

            </p>
            <p>We provide frozen ready to cook snacks from AGRO DELTA you can order and cook at home too! </p>
            <p> VEG, NON-VEG, IceCreams, Sodas, Sweets, Western Food, Indian Food, Family Section, Party Room, Best Food And Great Service.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;

import React from 'react';
import { Container, Button } from 'react-bootstrap';

function Order() {
  const googleFormLink = 'https://docs.google.com/forms/d/e/1FAIpQLSc0g-NW1VfOaDQoKByVZNZ0TCnnVPKlg0NEMuCfKogsnFsOnw/viewform';

  const handleOrderNow = () => {
    window.location.href = googleFormLink;
  };

  return (
    <section id="order" className="block order-block" style={{ backgroundColor: '#F4E3CB' }}>
      <Container fluid>
        <div className="title-holder text-center">
          <h2>Order Now for Frozen Ready To Cook Delights</h2>
        </div>
        <div className="btn-holder text-center">
          <Button onClick={handleOrderNow}>Order Now</Button>
        </div>
      </Container>
    </section>
  );
}

export default Order;
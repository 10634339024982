import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';

const worksData = [
  {
    id: 1,
    image: require('../assets/images/CHICKENSAMOSA.jpg'),
    title: 'Chicken Samosa 1KG 50PCS',
    subtitle: '₹400',
  },
  {
    id: 2,
    image: require('../assets/images/TANDOORINUGGETS.jpg'),
    title: 'Chicken Tandoori Nuggets 1KG 50PCS',
    subtitle: '₹650'
    
  },
  {
    id: 3,
    image: require('../assets/images/TANDOORIBURGER.jpg'),
    title: 'Chicken Tandoori Grill Patty 1KG 10PCS',
    subtitle: '₹600.00'
  },
  {
    id: 4,
    image: require('../assets/images/CHICKENCHEESEFINGERS.jpg'),
    title: 'Chicken Cheese Finger 1KG 40PCS',
    subtitle: '₹600'
  },
  {
    id: 5,
    image: require('../assets/images/CHICKENSPRINGROLL.jpg'),
    title: 'Chicken Spring Roll 1KG 33PCS',
    subtitle: '₹600'
  },
  {
    id: 6,
    image: require('../assets/images/CHICKENHARAKEBAB.jpg'),
    title: 'Chicken Hara Kebab 1KG 16PCS',
    subtitle: '₹700'
  },
  {
    id: 7,
    image: require('../assets/images/TANDOORIBURGER.jpg'),
    title: 'Chicken Garlic Grill Patty 1KG 10PCS',
    subtitle: '₹600.00'
  },
  {
    id: 8,
    image: require('../assets/images/TANDOORIBURGER.jpg'),
    title: 'Chicken Garlic Patty 1KG 20PCS',
    subtitle: '₹500.00'
  },
  {
    id: 9,
    image: require('../assets/images/CHICKENNUGGETS.jpg'),
    title: 'Chicken Nuggets 1KG 50PCS',
    subtitle: '₹650.00'
  },
  {
    id: 10,
    image: require('../assets/images/TANDOORIGRILLBURGER.jpg'),
    title: 'Chicken Breaded Tandoori 1KG 14PCS',
    subtitle: '₹600.00'
  },
  {
    id: 11,
    image: require('../assets/images/CHICKENCHEESEBALLS.jpg'),
    title: 'Chicken Cheese Balls 1KG 62PCS',
    subtitle: '₹600.00'
  },
  
]

function AppWorks() {
  const [active, setActive] = useState(1);

  // Calculate the index range for the current page
  const itemsPerPage = 6;
  const startIndex = (active - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the works data for the current page
  const currentWorks = worksData.slice(startIndex, endIndex);

  const handlePageClick = (number) => {
    setActive(number);
  };

  return (
    <section id="works" className="block works-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Our Ready To Cook Delicious Frozen Product Packets From Our Partner  DELTA AGRO FOODS</h2>
          <div className="subtitle">NON VEG</div>
        </div>
        <Row className='portfoliolist'>
          {currentWorks.map((works) => (
            <Col sm={4} key={works.id}>
              <div className='portfolio-wrapper'>
                <a href={works.link}>
                  <Image src={works.image} />
                  <div className='label text-center top'>
                    <h4 style={{ fontSize: '10px' }}>{works.title}</h4>
                    <p style={{ fontSize: '10px', position: 'relative', top: '-20px' }}>
                      {works.subtitle}
                    </p>
                    <p>{works.description}</p>
                  </div>
                </a>
              </div>
            </Col>
          ))}
        </Row>
        <Pagination>
          {Array.from({ length: Math.ceil(worksData.length / itemsPerPage) }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === active}
              onClick={() => handlePageClick(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </Container>
    </section>
  );
}

export default AppWorks;
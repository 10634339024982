import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';

function AppFooter() {
  // State variable to control the visibility of the "Go to Top" button
  const [showTopBtn, setShowTopBtn] = useState(false);

  // Add a scroll event listener when the component mounts
  useEffect(() => {
    // Event listener to track scroll position
    window.addEventListener("scroll", () => {
      // Check if the user has scrolled down by more than 400 pixels
      if (window.scrollY > 400) {
        // If scrolled down, set showTopBtn to true to display the button
        setShowTopBtn(true);
      } else {
        // If not scrolled down enough, hide the button
        setShowTopBtn(false);
      }
    });
    
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  // Function to scroll to the top of the page smoothly when the button is clicked
  function goTop() {
    window.scrollTo({
      top: 0,          // Scroll to the top of the page
      behavior: "smooth", // Use smooth scrolling animation
    });
  }

  // Render the footer
  return (
    <Container fluid>
      {/* Copyright notice */}
      <div className="copyright">&copy; Pakoda N Falooda Cafe 2023 Corporate. All Right Reserved.</div>
      
      {/* Social media links */}
      <div className="socials">
        <ul>
          <li><a href="https://www.youtube.com/@pakodanfaloodacafe2900"><i className="fab fa-youtube"></i></a></li>
          <li><a href="https://www.instagram.com/pakodanfaloodacafe/"><i className="fab fa-instagram"></i></a></li>
          <li><a href="https://www.swiggy.com/restaurants/pakoda-n-faluda-cafe-nana-peth-rasta-peth-pune-472659"><i className="fab fa-s"></i></a></li>
          <li><a href="https://www.zomato.com/pune/pakoda-n-faluda-cafe-rasta-peth?amp=1"><i className="fab fa-z"></i></a></li>
        </ul>
      </div>
      
      {/* Conditional rendering of the "Go to Top" button */}
      {showTopBtn && (
        <div className="go-top" onClick={goTop}></div>
      )}
    </Container>
  )
}

export default AppFooter;

import Carousel from 'react-bootstrap/Carousel';

// Define an array of data for hero slides
var heroData = [
  {
    id: 1,
    image: require('../assets/images/DUNS.jpg'),
    title: 'Duns Dairy New Poona Cold Drink House',
    description:'Partnered To Serve Pune Famous Faloodas, Icecreams And More!'
  },
  {
    id: 2,
    image: require('../assets/images/AGRO.jpg'),
    title: 'Delta Agro Foods',
    description:'Partnered To Serve Fresh Frozen Ready To Make Delights.'
  },
  {
    id: 3,
    image: require('../assets/images/CAFE.jpg'),
    title: 'Our Own Breakfast, Lunch, Dinner And Snacks',
    description:'From Different Types Of Pakodas To Burgers, Pav Bhaji, Tea, Coffee And More!'
  }
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
      {/* Create a Carousel component for hero slides */}
      <Carousel>
        {
          heroData.map(hero => {
            return (
              <Carousel.Item key={hero.id}>
                {/* Display an image for the slide */}
                <img
                  className="d-block w-100"
                  src={hero.image}
                  alt={"slide " + hero.id}
                />
                <Carousel.Caption>
                  {/* Display title and description for the slide */}
                  <h2>{hero.title}</h2>
                  <p>{hero.description}</p>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })
        }
      </Carousel>
    </section>
  );
}

export default AppHero;
